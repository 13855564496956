<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>Swimming Pool Settings</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Gap Between Bookings?</th>
                  <td>
                    <div class="pt-2 pb-2">
                      <v-text-field value="15" outlined hide-details>
                        <template v-slot:append>minutes</template>
                      </v-text-field>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Swimming Pool",
          disabled: true,
        },
        {
          text: "Settings",
          disabled: true,
        },
      ],
    };
  },
};
</script>
